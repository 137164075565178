import { Badge, Button } from 'antd';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import { useNavigate } from 'react-router-dom';
import NotificationsPreviewModal from '../notifications-preview-modal/notifications-preview-modal';
import { useState } from 'react';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { ReactComponent as BellIcon } from 'assets/icons/eiq-connect/bell.svg';

const NotificationsPreviewButton = () => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const [isNotificationModalOpened, setIsNotificationModalOpened] = useState(false);
  const notificationsData = eiqConnectStore.notificationPreviewData || {
    unreadCount: 0,
  };

  return (
    <>
      <Button
        size="large"
        className="round-primary"
        onClick={() => {
          if (isMobile) {
            navigate('/eiq-connect/notifications');
          } else {
            setIsNotificationModalOpened(true);
          }
        }}
        icon={
          <Badge className="notification-badge button-badge" dot={notificationsData?.unreadCount > 0}>
            <BellIcon />
          </Badge>
        }
      />

      <NotificationsPreviewModal isOpened={isNotificationModalOpened} setIsOpened={setIsNotificationModalOpened} />
    </>
  );
};

export default NotificationsPreviewButton;
