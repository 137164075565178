import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import TableBase from 'common/components/table/table-base';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTableContext } from '../../../../../common/components/table/table-context';
import { useMedia } from '../../../../../common/hooks/use-media/use-media.hook';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { AddressSelect } from 'features/eiq-connect/components/address-select/address-select';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { Button, Space } from 'antd';
import { ReactComponent as SearchIcon } from 'assets/icons/eiq-connect/search.svg';
import { ReactComponent as AddIcon } from 'assets/icons/eiq-connect/add.svg';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { formatAddressLabel } from 'common/helpers/address.helper';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';
import NotificationsPreviewButton from '../../notifications/notifications-preview-button/notifications-preview-button';

export const PetsList = observer(({ className }: any) => {
  const { isMobileOrTable } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();
  const isMainPath = location.pathname.endsWith('/pets');
  const isSearchPath = location.pathname.endsWith('/pets/search');
  const tableContext = useTableContext();

  const selectFilter = (
    <SearchFilter
      enableProgressiveSearch={true}
      allowClear={true}
      placeholder="By name"
      style={{ display: isSearchPath ? 'flex' : 'none' }}
      rulesForColumn={{
        name: FilterTypesEnum.Contains,
      }}
    />
  );

  const getAddresses = useCallback(() => {
    const addresses = eiqConnectStore?.residentDetails?.addresses || [];

    return addresses.filter((address) => address.ownershipType !== ResidentTypesEnum.Developer);
  }, []);

  const getAddressOptions = useCallback(() => {
    return getAddresses().map((item, index) => ({
      title: formatAddressLabel(item.address),
      value: index,
    }));
  }, [getAddresses]);

  const addressSelect = (
    <AddressSelect
      value={eiqConnectStore.selectedAddressesId ? eiqConnectStore.selectedAddressesId : undefined}
      placeholder="Filter pets by your addresses"
      className={isSearchPath ? 'display-none' : undefined}
      treeData={getAddressOptions()}
      onChange={(value) => eiqConnectStore.setSelectedAddressesId(value)}
    />
  );

  const actionButtons = (
    <Space size={10} className="eiq-action">
      <Button
        size="large"
        className="round-primary"
        onClick={() => {
          navigate('/eiq-connect/pets/search');
        }}
        icon={<SearchIcon />}
      />
      <Button
        size="large"
        className="round-primary"
        onClick={() => {
          navigate('/eiq-connect/pets/add');
        }}
        icon={<AddIcon />}
      />
      <NotificationsPreviewButton />
    </Space>
  );
  return (
    <div className={`eiq-guests-page ${className ?? ''}`}>
      <div className={`eiq-header ${isMainPath && 'main-page'}`}>
        <TopHeader>{!isSearchPath && actionButtons}</TopHeader>
        {isSearchPath ? <BottomHeader title="Search" /> : <h1 className="eiq-title">Pets</h1>}

        {!isMobileOrTable && (
          <>
            {selectFilter}
            {addressSelect}
            {!isSearchPath && actionButtons}
          </>
        )}
      </div>
      <div className="eiq-content">
        {isMobileOrTable && selectFilter}

        {tableContext?.dataState?.data?.length > 0 && isSearchPath && <div className="eiq-search-result">Result</div>}

        {isMobileOrTable && addressSelect}

        <TableBase wrapperClass="eiq-table" headerHeight={0} fixed={false} rowHeight={116} />
      </div>
    </div>
  );
});
