import './eiq-connect-list.scss';
import { Button, Space } from 'antd';
import React from 'react';
import { ReactComponent as ShareIcon } from 'assets/icons/eiq-connect/share.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/eiq-connect/search.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/eiq-connect/filter.svg';
import { ReactComponent as AddIcon } from 'assets/icons/eiq-connect/add.svg';
import TableBase from '../../../../../common/components/table/table-base';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddressSelect } from '../../../components/address-select/address-select';
import { observer } from 'mobx-react-lite';
import eiqConnectStore from '../../../store/eiq-connect.store';
import { FilterTypesEnum } from '../../../../../common/enums/filter-types.enum';
import { SearchFilter } from '../../../../../common/components/table/filters/search-filter/search-filter';
import { useMedia } from '../../../../../common/hooks/use-media/use-media.hook';
import { useTableContext } from '../../../../../common/components/table/table-context';
import { VoiceForm } from '../components/voice-form/voice-form';
import BottomHeader from '../../../components/bottom-header/bottom-header';
import TopHeader from '../../../components/top-header/top-header';
import configurationStore from 'common/stores/configuration/configuration.store';
import NotificationsPreviewButton from '../../notifications/notifications-preview-button/notifications-preview-button';

export const EiqConnectList = observer(({ className }: any) => {
  const { isMobileOrTable } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();
  const isMainPath = location.pathname.endsWith('/guests');
  const isSearchPath = location.pathname.endsWith('/guests/search');
  const tableContext = useTableContext();
  const defaultConfiguration = configurationStore.configuration?.default;

  const selectFilter = (
    <SearchFilter
      enableProgressiveSearch={true}
      allowClear={true}
      placeholder="By name, type, validity, address"
      style={{ display: isSearchPath ? 'flex' : 'none' }}
      rulesForColumn={{
        fullName: FilterTypesEnum.Contains,
        daysOfWeek: FilterTypesEnum.Contains,
        companyName: FilterTypesEnum.Contains,
        address: FilterTypesEnum.Contains,
        validity: FilterTypesEnum.Contains,
        typeToSearch: FilterTypesEnum.Contains,
      }}
    />
  );

  const addressSelect = (
    <AddressSelect
      value={eiqConnectStore.selectedAddressesId ? eiqConnectStore.selectedAddressesId : undefined}
      placeholder="Filter guests  by your addresses"
      className={isSearchPath ? 'display-none' : undefined}
      treeData={eiqConnectStore.addressOptions}
      onChange={(value) => eiqConnectStore.setSelectedAddressesId(value)}
    />
  );

  const actionButtons = (
    <Space size={10} className="eiq-action">
      {!defaultConfiguration?.isShareGuestConnect && (
        <Button
          size="large"
          className="round-primary"
          onClick={() => {
            navigate('/eiq-connect/guests/share');
          }}
          icon={<ShareIcon />}
        />
      )}
      <Button
        size="large"
        className="round-primary"
        onClick={() => {
          navigate('/eiq-connect/guests/search');
        }}
        icon={<SearchIcon />}
      />
      <Button
        size="large"
        className={`round-primary ${tableContext.getFilters() && 'eiq-active-filter'}`}
        onClick={() => {
          navigate('/eiq-connect/guests/filter');
        }}
        icon={<FilterIcon />}
      />
      <Button
        size="large"
        className="round-primary"
        onClick={() => {
          navigate('/eiq-connect/guests/add');
        }}
        icon={<AddIcon />}
      />
      <NotificationsPreviewButton />
    </Space>
  );

  return (
    <div className={`eiq-guests-page ${className ?? ''}`}>
      <div className={`eiq-header ${isMainPath && 'main-page'}`}>
        <TopHeader>{!isSearchPath && actionButtons}</TopHeader>
        {isSearchPath ? <BottomHeader title="Search" /> : <h1 className="eiq-title">Guests</h1>}

        {!isMobileOrTable && (
          <>
            {selectFilter}
            {addressSelect}
            {!isSearchPath && actionButtons}
          </>
        )}
      </div>

      <div className="eiq-content">
        {isMobileOrTable && selectFilter}

        {tableContext?.dataState?.data?.length > 0 && isSearchPath && <div className="eiq-search-result">Result</div>}

        {isMobileOrTable && addressSelect}

        <TableBase wrapperClass="eiq-table" headerHeight={0} fixed={false} rowHeight={116} />

        <VoiceForm />
      </div>
    </div>
  );
});
