import './protect-text.scss';
import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { ProtectTextProps } from './protect-text.types';

export const ProtectText = ({ text, className }: ProtectTextProps) => {
  const [showCode, setShowCode] = useState(false);

  const toggleCodeVisibility = () => {
    setShowCode(!showCode);
  };

  return (
    <div className={`protect-text ${className ?? ''}`}>
      <span className={`${showCode ? '' : 'hide-code'}`}>{text}</span>
      {showCode ? <EyeInvisibleOutlined onClick={toggleCodeVisibility} /> : <EyeOutlined onClick={toggleCodeVisibility} />}
    </div>
  );
};
