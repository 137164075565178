import { Button, Col, Divider, Form, Input, Radio, Row } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { ReactComponent as LoginIcon } from 'assets/icons/eiq-connect/login.svg';
import { signUpValidation } from 'features/eiq-connect-login/validation/login.validation';
import '../login/login.scss';
import wgSignUpService from 'features/eiq-connect-login/service/sign-up.service';
import { notification } from 'common/utils/notification';
import { getLogin } from 'common/helpers/login.helper';

const SignUpPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const login = getLogin(values.login);

    wgSignUpService
      .verify(login, values.pin)
      .then((_res) => {
        userAuthStore.setSignUpValues({ login: login, pin: values.pin });
        sessionStorage.setItem(
          'userData',
          JSON.stringify({
            login: login,
            pin: values.pin,
          }),
        );
        navigate('/verification');
      })
      .catch((error: any) => {
        if (error.response.status === 404) {
          notification.error({
            message: error.response.data.message,
          });
        }
      });
  };

  if (userAuthStore.isLogin) {
    return <Navigate to="/eiq-connect" />;
  }

  return (
    <FormWrapper
      form={form}
      className="eiq-connect-auth-form-wrapper"
      onFinish={onFinish}
      name="validateOnly"
      layout="vertical"
      autoComplete="on"
      validateTrigger="onSubmit"
      initialValues={{ remember: true }}>
      <Row>
        <Col>
          <div className="title-wrapper">
            <Button.Group className="full-width" style={{ display: 'flex' }} size="large">
              <Button style={{ flex: 1, fontWeight: 'bold' }} onClick={() => navigate('/login')}>
                Log in
              </Button>
              <Button style={{ flex: 1, fontWeight: 'bold' }} type="primary">
                Account activation
              </Button>
            </Button.Group>
          </div>
          <br />
          <div className="text">
            Please use the email registered with your association to activate your account. You should have received your PIN number in the
            welcome email from EntranceIQ.
          </div>
          <div className="text">In the next step, we will send you a verification code via email to help you set up your password.</div>
        </Col>
      </Row>

      <div>
        <Form.Item name="login" label="Email" rules={signUpValidation.login}>
          <Input name="login" placeholder="Enter email" autoCapitalize="none" autoCorrect="off" />
        </Form.Item>

        <Divider className="auth-diver" />

        <Form.Item name="pin" label="PIN code" rules={signUpValidation.pinCode}>
          <Input name="pin" placeholder="Enter your PIN code" />
        </Form.Item>
      </div>

      <div className="action-line">
        <Button htmlType="submit" type="primary" icon={<LoginIcon />} style={{ width: '100%' }}>
          Sign up
        </Button>
      </div>

      <div className="text text-center footer">
        Already activated your account? <Link to="/login">Sign in</Link>
      </div>
      {/* <div className="font-size-dropdown">
        <p>Font size</p>
        <Select options={fontSizeOptions} defaultValue={16}></Select>
      </div> */}
    </FormWrapper>
  );
};

export const SignUp = observer(SignUpPage);
