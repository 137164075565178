import { Divider } from 'antd';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { DATE_SHORT_MMM_DD_YYYY, TIME_h_mma } from 'common/constans/date.constants';
import { useMedia } from 'common/hooks/use-media/use-media.hook';

dayjs.extend(isToday);

const DateTimeView = ({ dateTime, className }: any) => {
  const { isMobile } = useMedia();
  const date = dayjs(dateTime);

  if (isMobile) {
    const dateToDisplay = date.isToday() ? date.format(TIME_h_mma) : date.format('MMM DD');

    return <div className={className}>{dateToDisplay}</div>;
  }

  return (
    <div className={className}>
      {date.format(DATE_SHORT_MMM_DD_YYYY)}
      <Divider type="vertical" />
      {date.format(TIME_h_mma)}
    </div>
  );
};

export default DateTimeView;
