import React, { createContext, useState, useContext, useEffect, useReducer, useCallback } from 'react';
import { ColumnVisibilityProps, FilterType, FilterValue, ITableControls, SortBy, TableContextProviderProps } from './table.types';
import { IResponseList } from '../../models/response-list.interface';
import { FilterTypesEnum } from '../../enums/filter-types.enum';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SortOrder } from 'react-base-table';
import { IDateRange } from '../../models/date-range.interface';
dayjs.extend(utc);

const TableContext = createContext<ITableControls | undefined>(undefined);

function debouncePromise<T>(func: (...args: any[]) => Promise<T>, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (...args: any[]) {
    clearTimeout(timeout);
    return new Promise<T>((resolve, reject) => {
      timeout = setTimeout(() => {
        func(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
    });
  };
}

// Define action types
const SET_DATA = 'SET_DATA';
const SET_LOADING = 'SET_LOADING';
const SET_LOADING_MORE = 'SET_LOADING_MORE';
const ADD_DATA = 'ADD_DATA';
const SET_DATA_SOURCE = 'SET_DATA_SOURCE';
const ADD_OFFSET = 'ADD_OFFSET';
const SET_OFFSET = 'SET_OFFSET';
const SET_TOTAL = 'SET_TOTAL';
const SET_SELECTED_ROW = 'SET_SELECTED_ROW';
const UPDATE_SELECTED_ROW = 'UPDATE_SELECTED_ROW';
const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';

// Reducer function
function reducer(state: any, action: any) {
  switch (action.type) {
    case SET_DATA_SOURCE:
      return { ...state, dataSource: [...action.payload] };
    case SET_DATA:
      return { ...state, data: [...action.payload] };
    case ADD_DATA:
      return { ...state, data: [...state.data, ...action.payload] };
    case SET_OFFSET:
      return { ...state, offset: action.payload };
    case ADD_OFFSET:
      return { ...state, offset: state.offset + action.payload };
    case SET_TOTAL:
      return { ...state, total: action.payload };
    case SET_SELECTED_ROW:
      return { ...state, selectedRow: action.payload };
    case UPDATE_SELECTED_ROW:
      return {
        ...state,
        data: state.data.map((item: any) => (item.id === action.payload.id ? action.payload : item)),
        dataSource: state.data.map((item: any) => (item.id === action.payload.id ? action.payload : item)),
        selectedRow: action.payload,
      };
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload,
      };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_LOADING_MORE:
      return { ...state, loadingMore: action.payload };
    default:
      return state;
  }
}

// Initial state
const initialState = {
  dataSource: [], // only for client side
  loading: false,
  loadingMore: false,
  data: [],
  offset: 0,
  total: 0,
  selectedRow: null,
  selectedRows: [],
};

export const useTableContext = <RowTyp = any,>(): ITableControls<RowTyp> => {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error('useTableContext must be used within a TableContextProvider');
  }
  return context as ITableControls<RowTyp>;
};

export const useTableControls = ({
  initialColumns,
  onFetch,
  defaultSort,
  onSelectedRow,
  onSelectedRows,
  data,
  clientSide,
  defaultAndFilter,
  defaultOrFilter,
  pagination = null,
}: any): ITableControls => {
  const [columns, setColumns] = useState(initialColumns);

  const [filtersAnd, setFiltersAnd] = useState<Record<string, FilterType>>(defaultAndFilter ?? {});
  const [filtersOR, setFiltersOR] = useState<Record<string, FilterType>>(defaultOrFilter ?? {});

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    limit: pagination?.limit || 20,
    offset: pagination?.offset || 0,
  });
  const [sortBy, setSortedBy] = useState<SortBy>(defaultSort);

  // Sort
  const setSortBy = (sort: SortBy) => {
    setSortedBy(sort);
  };

  const getSortBy = () => {
    if (!sortBy) {
      return '';
    }
    return `${sortBy.order === 'desc' ? '-' + sortBy.key : '+' + sortBy.key}`;
  };

  // Client side Data wathcer
  useEffect(() => {
    dispatch({ type: SET_DATA_SOURCE, payload: data ? JSON.parse(JSON.stringify(data)) : [] });
  }, [data]);

  // Sort
  const handleSortColumn = () => {};

  // update the filters
  // example after generation: guestType=1 OR(is innerFilterType) guestType=2,(outFilterType === AND) directions=1
  const updateFilter = (key: string, value: FilterType, outerFilterType: 'OR' | 'AND') => {
    if (outerFilterType === 'OR') {
      setFiltersOR((filters) => ({ ...filters, [key]: value }));
    } else {
      setFiltersAnd((filters) => ({ ...filters, [key]: value }));
    }
  };

  const setFilter = (key: string, value: FilterType, outerFilterType: 'OR' | 'AND') => {
    if (outerFilterType === 'OR') {
      setFiltersOR((filters) => ({ [key]: value }));
    } else {
      setFiltersAnd((filters) => ({ [key]: value }));
    }
  };

  const setPage = (page: number) => {
    dispatch({ type: SET_OFFSET, payload: state.limit * (page - 1) });
  };

  const resetFilter = (outerFilterType: 'OR' | 'AND') => {
    if (outerFilterType === 'OR') {
      setFiltersOR({});
    } else {
      setFiltersAnd({});
    }
  };

  const updateFilters = (filters: Record<string, FilterType>, outerFilterType: 'OR' | 'AND') => {
    if (outerFilterType === 'OR') {
      setFiltersOR((oldFilters) => ({ ...oldFilters, ...filters }));
    } else {
      setFiltersAnd((oldFilters) => ({ ...oldFilters, ...filters }));
    }
  };

  const getFilters = () => {
    const handleSameNameFilters = ([key, value]: [string, FilterType]) => {
      const values = Array.isArray(value) ? value : [value];
      const innerFilters = values.filter((item) => item?.value !== null && item?.value !== undefined && item?.value !== '');
      let filterStrings = '';

      innerFilters.forEach((filter, index) => {
        filterStrings += `${key}${filter?.operator}${filter?.value}`;
        if (index < innerFilters.length - 1) {
          filterStrings += `${filter?.innerFilterType === 'AND' ? ',' : ' OR '}`;
        }
      });
      return filterStrings;
    };

    const filtersAndString = Object.entries(filtersAnd)
      .filter(([_, value]) => value && (Array.isArray(value) ? value.length : true)) // Check for truthy and non-empty arrays
      .map(handleSameNameFilters)
      .join(',');

    const filtersOrString = Object.entries(filtersOR)
      .filter(([_, value]) => value && (Array.isArray(value) ? value.length : true)) // Check for truthy and non-empty arrays
      .map(handleSameNameFilters)
      .join(' OR ');

    if (!filtersAndString && !filtersOrString) {
      return null;
    } else if (filtersAndString && !filtersOrString) {
      return filtersAndString;
    } else if (!filtersAndString && filtersOrString) {
      return filtersOrString;
    } else {
      return `${filtersAndString},${filtersOrString}`;
    }
  };

  const resetFilters = () => {
    setFiltersAnd({});
    setFiltersOR({});
  };

  // update the visible columns
  const updateColumns = (columns: any) => {
    setColumns(columns);
  };

  const hideColumn = (columnKey: string, isHidden: boolean) => {
    if (!columns) {
      return;
    }
    updateColumns(columns.map((col: any) => (col.key === columnKey ? { ...col, hidden: isHidden } : col)));
  };

  const hideColumns = (columnsKeyVisibility: ColumnVisibilityProps) => {
    if (!columnsKeyVisibility) {
      return;
    }

    const newColumns = columns.map((column: any) => {
      // For each column, if there's a matching entry in columnsKeyVisibility,
      // use its value to set the hidden property; otherwise, leave the column unchanged
      for (const [key, isVisible] of Object.entries(columnsKeyVisibility)) {
        if (column.key === key) {
          return { ...column, hidden: !isVisible };
        }
      }
      return column;
    });

    updateColumns(newColumns);
  };

  const onFetchDebounce = useCallback(debouncePromise<IResponseList<any>>(onFetch, 100), [onFetch]);

  const refetchData = useCallback(() => {
    if (!onFetch) {
      return;
    }
    dispatch({ type: SET_LOADING, payload: true });
    onFetchDebounce({ offset: state.offset, limit: state.limit }, getFilters(), getSortBy()).then((res: IResponseList<any>) => {
      if (!res?.items) {
        return;
      }
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: SET_LOADING_MORE, payload: false });
      dispatch({ type: SET_DATA, payload: res.items });
      dispatch({ type: SET_TOTAL, payload: res.totalCount });
    });
  }, [onFetch, getFilters, getSortBy]);

  // Load data during Scroll
  const fetchData = useCallback(() => {
    if (state.total !== 0 && state.data.length >= state.total) {
      // If we've loaded all items, do nothing
      return;
    }

    if (!onFetch) {
      return;
    }

    dispatch({ type: state.offset === 0 ? SET_LOADING : SET_LOADING_MORE, payload: true });

    onFetchDebounce({ offset: state.offset, limit: state.limit }, getFilters(), getSortBy()).then((res: IResponseList<any>) => {
      if (!res?.items) {
        return;
      }
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: SET_LOADING_MORE, payload: false });
      dispatch({ type: ADD_DATA, payload: res.items });
      !pagination && dispatch({ type: ADD_OFFSET, payload: res.items.length });
      dispatch({ type: SET_TOTAL, payload: res.totalCount });
    });
  }, [state.offset, state.total, onFetch, getFilters, getSortBy]);

  // Load data after init
  useEffect(() => {
    if (!clientSide) {
      refetchData();
    }
  }, []);

  const applyFilter = (row: any, key: string, filter: FilterType) => {
    if (Array.isArray(filter)) {
      return filter.some((singleFilter) => applySingleFilter(row, key, singleFilter));
    } else {
      return applySingleFilter(row, key, filter);
    }
  };

  const applySingleFilter = (row: any, key: string, filter: FilterValue | null) => {
    if (!filter?.value) return true;

    const columnConfig = columns.find((col: any) => col.dataKey === key);
    let rowValue: string | number | Date = row[key];
    let filterValue: string | number | Date | IDateRange = filter.value;

    if (typeof filterValue === 'object' && 'from' in filterValue) {
      const filterStartDate = dayjs(filterValue.from).startOf('day').valueOf();
      const filterEndDate = dayjs(filterValue.to).endOf('day').valueOf();

      const cellValue = columnConfig?.getValue ? columnConfig.getValue(row) : rowValue;

      if (cellValue?.startDate && cellValue?.endDate) {
        const cellStartDate = dayjs(cellValue.startDate).startOf('day').valueOf();
        const cellEndDate = dayjs(cellValue.endDate).endOf('day').valueOf();

        return cellStartDate <= filterEndDate && cellEndDate >= filterStartDate;
      }
    }

    // Handle date comparison
    if (typeof filter.value === 'string' && isUTCDate(filter.value)) {
      filterValue = dayjs.utc(filter.value).valueOf();
      rowValue = isUTCDate(row[key]) ? dayjs.utc(row[key]).valueOf() : rowValue;
    }

    // Handle case insensitive string comparison
    if (typeof rowValue === 'string' && typeof filterValue === 'string') {
      rowValue = rowValue.toLowerCase();
      filterValue = filterValue.toLowerCase();
    } else if (typeof filterValue === 'string') {
      rowValue = (columnConfig?.getValue ? columnConfig.getValue(row) : rowValue?.toString())?.toLowerCase?.();
      filterValue = filterValue.toLowerCase();
    }

    switch (filter.operator) {
      case FilterTypesEnum.GreaterThan:
        return rowValue > filterValue;
      case FilterTypesEnum.LessThan:
        return rowValue < filterValue;
      case FilterTypesEnum.GreaterThanOrEqual:
        return rowValue >= filterValue;
      case FilterTypesEnum.LessThanOrEqual:
        return rowValue <= filterValue;
      case FilterTypesEnum.Equals:
        return rowValue === filterValue;
      case FilterTypesEnum.NotEqual:
        return rowValue !== filterValue;
      case FilterTypesEnum.Contains:
        return String(rowValue).includes(String(filterValue));
      default:
        return false;
    }
  };

  const applyFilters = (): void => {
    let filteredData = state.dataSource.filter((row: any) => {
      let andFilterExists = false;
      let andFilterPassed = true;
      let orFilterExists = false;
      let orFilterPassed = false;

      for (let key in filtersAnd) {
        const filter = filtersAnd[key];
        if (filter) {
          andFilterExists = true;
          if (!applyFilter(row, key, filter)) {
            andFilterPassed = false;
            break;
          }
        }
      }

      for (let key in filtersOR) {
        const filter = filtersOR[key];
        if (filter) {
          orFilterExists = true;
          if (applyFilter(row, key, filter)) {
            orFilterPassed = true;
            break;
          }
        }
      }

      return (andFilterPassed || !andFilterExists) && (orFilterPassed || !orFilterExists);
    });

    if (sortBy) {
      sortData(filteredData, sortBy.key, sortBy.order);
    } else {
      dispatch({ type: SET_DATA, payload: filteredData });
    }
  };

  const sortData = (filteredData: any, key: React.Key, order: SortOrder): void => {
    const columnConfig = columns.find((col: any) => col.dataKey === key);

    if (!columnConfig) {
      console.error(`Column not found for key: ${key}`);
      return;
    }

    const sortedData = [...filteredData].sort((a, b) => {
      let valueA = columnConfig?.getValue ? columnConfig.getValue(a) : a[key];
      let valueB = columnConfig?.getValue ? columnConfig.getValue(b) : b[key];

      const type = getDataType(valueA);

      if (type === 'number') {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      } else if (type === 'date') {
        return order === 'asc'
          ? dayjs.utc(valueA as string).diff(dayjs.utc(valueB as string))
          : dayjs.utc(valueB as string).diff(dayjs.utc(valueA as string));
      } else if (type === 'boolean') {
        return order === 'asc' ? (valueA ? 1 : -1) - (valueB ? 1 : -1) : (valueB ? 1 : -1) - (valueA ? 1 : -1);
      } else {
        return order === 'asc'
          ? ((valueA as string) ?? '').localeCompare((valueB as string) ?? '')
          : ((valueB as string) ?? '').localeCompare((valueA as string) ?? '');
      }
      return 0;
    });

    dispatch({ type: SET_DATA, payload: sortedData });
  };

  // Load data after Filter changed
  useEffect(() => {
    if (clientSide) {
      applyFilters();
    } else {
      refetchData();
    }
  }, [filtersAnd, filtersOR, sortBy, state.dataSource, dispatch]);

  useEffect(() => {
    if (!pagination) {
      return;
    }

    refetchData();
  }, [state.offset]);

  const setSelectedRow = (selectedRow: any) => {
    dispatch({ type: SET_SELECTED_ROW, payload: selectedRow });
    if (onSelectedRow) {
      onSelectedRow(selectedRow);
    }
  };

  const setSelectedRows = (selectedRows: any) => {
    dispatch({ type: SET_SELECTED_ROWS, payload: selectedRows });
    if (onSelectedRows) {
      onSelectedRows(selectedRows);
    }
  };

  const getSelectedRow = () => {
    return state.selectedRow;
  };

  const getSelectedRows = () => {
    return state.selectedRows;
  };

  const updateSelectedRow = (selectedRow: any) => {
    dispatch({ type: UPDATE_SELECTED_ROW, payload: selectedRow });
    if (onSelectedRow) {
      onSelectedRow(selectedRow);
    }
  };

  return {
    isClientSide: clientSide,
    sortBy,
    setSortBy,
    setSelectedRow,
    dataState: state,
    refetchData,
    fetchData,
    filtersAnd,
    filtersOR,
    setFilter,
    getFilters,
    resetFilter,
    updateFilters,
    updateFilter,
    resetFilters,
    columns,
    updateColumns,
    hideColumn,
    hideColumns,
    getSelectedRow,
    updateSelectedRow,
    setSelectedRows,
    getSelectedRows,
    setPage,
    pagination: pagination
      ? {
          limit: state.limit,
          offset: state.offset,
        }
      : null,
  };
};

export const TableContextProvider: React.FC<TableContextProviderProps> = ({ children, controls }) => (
  <TableContext.Provider value={controls}>{children}</TableContext.Provider>
);

const isUTCDate = (date: string): boolean => {
  return dayjs.utc(date, 'YYYY-MM-DD', true).isValid();
};

type DataType = 'string' | 'number' | 'boolean' | 'date';

const getDataType = (value: any): DataType => {
  const valueType = typeof value;

  if (valueType === 'string' && isUTCDate(value)) {
    return 'date';
  } else if (valueType === 'number' || valueType === 'string' || valueType === 'boolean') {
    return valueType;
  }

  return 'string'; // fallback
};

export default TableContextProvider;
