import { TextWithTitleProps } from './text-with-title.types';

export const TextWithTitle: React.FC<TextWithTitleProps> = ({ title, text }) => {
  return (
    <>
      <h3>{title}</h3>
      {text}
    </>
  );
};
