import { states } from 'common/constans/states.constants';
import { extendedStartPhrases, extendedVerbs } from './speech-to-text.const';

export const textToGuestForm = (text: string) => {
  const result: any = {};
  const typeValuePattern = ['delivery', 'family', 'guest', 'service', 'ride share', 'employee', 'urgent', 'undefined'].join('|');
  const validityValuePattern = [
    'one time',
    'one-time',
    'permanent',
    'today',
    'tomorrow',
    'to morrow',
    'date range',
    'selected date range',
  ].join('|');
  const namePatternArray = ['guest name', 'visitor name', 'name'];
  const typePatternArray = ['guest type', 'visitor type', 'type'];
  const companyPatternArray = ['company'];
  const validityPatternArray = ['validity'];
  const preposition = ['with', 'and', 'with the', 'with an'];
  const carNumberPatternArray = ['license plate number', 'license number', 'number'];
  const carStatePatternArray = ['state', 'license state'];
  const carStateValuePattern = states.join('|');

  const stopWords = [
    namePatternArray.join('|'),
    typeValuePattern,
    typePatternArray.join('|'),
    validityPatternArray.join('|'),
    companyPatternArray.join('|'),
    carNumberPatternArray.join('|'),
    carStateValuePattern,
    carStatePatternArray.join('|'),
    typePatternArray.map((type) => preposition.map((prep) => `${prep} ${type}`).join('|')).join('|'),
    namePatternArray.map((type) => preposition.map((prep) => `${prep} ${type}`).join('|')).join('|'),
    validityPatternArray.map((type) => preposition.map((prep) => `${prep} ${type}`).join('|')).join('|'),
    companyPatternArray.map((type) => preposition.map((prep) => `${prep} ${type}`).join('|')).join('|'),
    carNumberPatternArray.map((type) => preposition.map((prep) => `${prep} ${type}`).join('|')).join('|'),
  ].join('|');

  const patterns = [
    {
      field: 'Unnamed',
      regex: new RegExp(`(.*?)\\s*(${typeValuePattern})\\s*(.*?)\\s*(${validityValuePattern})`, 'i'),
    },
    {
      field: 'Unnamed2',
      regex: new RegExp(`(.*?)\\s*(${typeValuePattern})\\s*(.*)\\s*`, 'i'),
    },
    {
      field: 'companyName',
      regex: new RegExp(
        `(?:${extendedStartPhrases})?\\s*(?:${companyPatternArray.join('|')})\\s*(?:${extendedVerbs.join(
          '|',
        )})?\\s*([\\w\\s]+?)\\s*(?:(?:${stopWords})|$)`,
        'i',
      ),
    },
    {
      field: 'name',
      regex: new RegExp(
        `(?:${extendedStartPhrases})?\\s*(?:${namePatternArray.join('|')})\\s*(?:${extendedVerbs.join(
          '|',
        )})?\\s*([\\w\\s]+?)\\s*(?:(?:${stopWords})|$)`,
        'i',
      ),
    },
    {
      field: 'type',
      regex: new RegExp(`(${typeValuePattern})`, 'i'),
    },
    {
      field: 'validity',
      regex: new RegExp(`(${validityValuePattern})`, 'i'),
    },
    {
      field: 'carNumber',
      regex: new RegExp(
        `(?:${extendedStartPhrases})?\\s*(?:${carNumberPatternArray.join('|')})\\s*(?:${extendedVerbs.join(
          '|',
        )})?\\s*([\\w\\s]+?)\\s*(?:(?:${stopWords})|$)`,
        'i',
      ),
    },
    {
      field: 'carState',
      regex: new RegExp(`(${carStateValuePattern})`, 'i'),
    },
  ];

  let remainingText = text.replaceAll(/[.,?!:]/g, '');
  const isAnyNamedPatternMatched =
    namePatternArray.some((pattern) => remainingText.toLowerCase().includes(pattern)) ||
    typePatternArray.some((pattern) => remainingText.toLowerCase().includes(pattern)) ||
    companyPatternArray.some((pattern) => remainingText.toLowerCase().includes(pattern)) ||
    carNumberPatternArray.some((pattern) => remainingText.toLowerCase().includes(pattern));

  for (const pattern of patterns) {
    const match = remainingText.match(pattern.regex);
    if (match) {
      if (
        (pattern.field === 'Unnamed' || pattern.field === 'Unnamed2' || pattern.field === 'carState') &&
        !isAnyNamedPatternMatched &&
        match[2]
      ) {
        if (match[1]) {
          result.name = match[1].trim();
        }
        if (match[2]) {
          result.type = match[2].trim();
        }
        if (match[3]) {
          result.companyName = match[3].trim();
        }
        if (match[4]) {
          result.validity = match[4].trim();
        }
        if (match[5]) {
          result.carNumber = match[5].trim();
        }
        if (match[6]) {
          result.carState = match[6].trim();
        }
        break;
      } else if (pattern.field !== 'Unnamed' && match[1]) {
        // remainingText = remainingText.replace(match[0], ''); // Remove the parsed part
        result[pattern.field] = match[1].trim();
      }
    }
  }

  if (result.type) {
    result.type = typeMap[result.type.toLowerCase()] as string;
  }

  if (result.validity) {
    result.validity = validityMap[result.validity.toLowerCase()] as string;
  }

  console.log('\n' + remainingText + ':');
  return result;
};

const typeMap: any = {
  delivery: 'Delivery',
  family: 'Family',
  guest: 'Guest',
  service: 'Service',
  'ride share': 'RideShare',
  urgent: 'UrgentService',
  employee: 'Employee',
  undefined: 'Undefined',
};

const validityMap: any = {
  'one time': 'OneTime',
  'one-time': 'OneTime',
  permanent: 'Permanent',
  today: 'Today',
  tomorrow: 'Tomorrow',
  'to morrow': 'Tomorrow',
  'date range': 'DateRange',
  'selected date range': 'DateRange',
};
