// Visitor
import { TrafficLogVisitorType } from './common.constants';
import { generateOption } from '../helpers/generate-option.helper';

export const visitorTypeMap: Record<TrafficLogVisitorType, string> = {
  [TrafficLogVisitorType.Undefined]: 'Undefined',
  [TrafficLogVisitorType.Resident]: 'Resident',
  [TrafficLogVisitorType.Guest]: 'Guest',
  [TrafficLogVisitorType.Service]: 'Service',
  [TrafficLogVisitorType.Delivery]: 'Delivery',
  [TrafficLogVisitorType.RideShare]: 'Ride share',
  [TrafficLogVisitorType.Family]: 'Family',
  [TrafficLogVisitorType.Employee]: 'Employee',
  //[TrafficLogVisitorType.Party]: 'Party',
  [TrafficLogVisitorType.UnlistedGuest]: 'Unlisted guest',
  [TrafficLogVisitorType.UnlistedDelivery]: 'Unlisted delivery',
  [TrafficLogVisitorType.UnlistedService]: 'Unlisted service',
  [TrafficLogVisitorType.UnlistedRideShare]: 'Unlisted ride share',
  [TrafficLogVisitorType.UrgentService]: 'Urgent service',
  [TrafficLogVisitorType.UnlistedUrgentService]: 'Unlisted urgent service',
  [TrafficLogVisitorType.UnlistedEmployee]: 'Unlisted employee',
};

export const visitorTypeOptions = generateOption(TrafficLogVisitorType, visitorTypeMap);
export const visitorTypeMapColorMap: Record<TrafficLogVisitorType, string> = {
  [TrafficLogVisitorType.Undefined]: '#CCCCCC',
  [TrafficLogVisitorType.Resident]: '#C1FFEA',
  [TrafficLogVisitorType.Guest]: '#EECAFF',
  [TrafficLogVisitorType.Service]: '#C1FFEA',
  [TrafficLogVisitorType.Delivery]: '#FFD889',
  [TrafficLogVisitorType.RideShare]: '#FFD889',
  [TrafficLogVisitorType.Family]: '#C1FFEA',
  [TrafficLogVisitorType.Employee]: '#ffd1ee',
  //[TrafficLogVisitorType.Party]: '#C1FFEA',
  [TrafficLogVisitorType.UnlistedGuest]: '#FFA39E',
  [TrafficLogVisitorType.UnlistedService]: '#FFA39E',
  [TrafficLogVisitorType.UnlistedDelivery]: '#FFA39E',
  [TrafficLogVisitorType.UnlistedRideShare]: '#FFA39E',
  [TrafficLogVisitorType.UrgentService]: '#C1FFEA',
  [TrafficLogVisitorType.UnlistedUrgentService]: '#FFA39E',
  [TrafficLogVisitorType.UnlistedEmployee]: '#FFA39E',
};
