import React from 'react';
import { Badge, Button, Modal, Space } from 'antd';
import { notificaitonService } from 'common/services/notification-service/notification.service';
import { useNavigate } from 'react-router-dom';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import DateTimeView from 'features/eiq-connect/components/date-time-view/date-time-view';
import { INotification } from 'common/services/notification-service/notification.service.types';
import { ReactComponent as MessageIcon } from 'assets/icons/eiq-connect/message.svg';
import { ReactComponent as CheckAllIcon } from 'assets/icons/eiq-connect/check-all.svg';
import { observer } from 'mobx-react-lite';

export const NotificationsPreviewModal = observer(({ isOpened, setIsOpened }: any) => {
  const navigate = useNavigate();
  const notificationsData = eiqConnectStore.notificationPreviewData;

  const navigateToAll = () => {
    navigate('/eiq-connect/notifications');
  };

  const readAllNotifications = () => {
    notificaitonService.readAllNotifications().then(() =>
      eiqConnectStore.setNotificationPreviewData({
        items: notificationsData?.items.map((x) => ({ ...x, isRead: true })) as INotification[],
        unreadCount: 0,
      }),
    );
  };

  return (
    <Modal
      title="Notification"
      style={{ float: 'right', right: '50px', top: '50px' }}
      open={isOpened}
      onOk={() => setIsOpened(false)}
      onCancel={() => setIsOpened(false)}
      width={600}
      footer={false}>
      <Space size={16} className="notifications-preview-modal" direction={'vertical'}>
        <div className="notifications-list">
          <div>Stay updated with your latest notifications</div>
          <div className="actions">
            <div className="filter">
              <Button type="link" onClick={() => navigateToAll()}>
                All
              </Button>
              <Button type="link" onClick={() => navigate('/eiq-connect/notifications/unread')}>
                Unread ({notificationsData?.unreadCount})
              </Button>
            </div>
            <div className="mark-all">
              <Button icon={<CheckAllIcon />} type="link" onClick={() => readAllNotifications()}>
                Mark all as read
              </Button>
            </div>
          </div>
          <div>Today</div>
          {notificationsData &&
            notificationsData.items.map((x) => (
              <div className="notification-data">
                <div className="notification-icon-and-body">
                  <div className="notification-icon">
                    <MessageIcon />
                  </div>
                  <div className="notification-content">
                    <div className={`message-body ${!x.isRead ? 'unread' : ''}`} dangerouslySetInnerHTML={{ __html: x.message }} />
                    <DateTimeView dateTime={x.createdAt} />
                  </div>
                </div>
                <Badge className="notification-badge" dot={!x.isRead}></Badge>
              </div>
            ))}
        </div>
        <Button className="show-all-btn" type="link" onClick={() => navigateToAll()} block>
          All Notification
        </Button>
      </Space>
    </Modal>
  );
});

export default NotificationsPreviewModal;
