import { TextWithTitleProps } from 'common/components/text/text-with-title.types';

export const termsAndConditionsData: TextWithTitleProps[] = [
  {
    title: '1. Acceptance of Terms',
    text: (
      <p>
        By subscribing to or using our email and SMS communications, you agree to these Terms and Conditions. EntranceIQ reserves the right
        to modify these terms at any time. Any changes will be effective immediately upon posting to our website. Continued use of our
        services constitutes your acceptance of any such changes.
      </p>
    ),
  },
  {
    title: '2. Purpose of Communications',
    text: (
      <>
        <p>Email and SMS communications from EntranceIQ are intended to:</p>
        <ul>
          <li>Provide updates on service features, community management tools, and platform enhancements.</li>
          <li>Share important notifications regarding resident or community updates.</li>
          <li>
            Communicate alerts, such as security notifications, guest entry updates, or other essential information related to community
            safety and operations.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: '3. Subscription and Consent',
    text: (
      <p>
        By providing your contact information and subscribing to EntranceIQ’s communication services, you agree to receive emails, SMS
        messages, and push notifications from EntranceIQ. This consent includes, but is not limited to, communications related to community
        updates, alerts, or promotional information. You may opt-out at any time by following the instructions provided in the message or
        contacting us directly. However, opting out of certain communications may limit your access to essential updates, particularly
        security-related notifications.
      </p>
    ),
  },
  {
    title: '4. User Obligations',
    text: (
      <p>
        Users must ensure that the contact information provided to EntranceIQ is accurate and updated. You are responsible for maintaining
        the confidentiality of your account and any communications sent through your registered email or phone number. Users must not use
        our communication services for any unlawful purposes, including the transmission of any message or information that violates any
        laws or the rights of others.
      </p>
    ),
  },
  {
    title: '5. Privacy and Data Protection',
    text: (
      <p>
        EntranceIQ respects your privacy and is committed to protecting your personal data. Our communications are governed by our Privacy
        Policy which outlines how we collect, use, and store your data. By agreeing to these terms, you consent to the processing of your
        data in accordance with our Privacy Policy.
      </p>
    ),
  },
  {
    title: '6. Message Frequency',
    text: (
      <>
        <p>The frequency of messages will vary based on the nature of the communication:</p>
        <ul>
          <li>Alert Notifications: Sent immediately as required.</li>
          <li>General Updates: Periodically based on service enhancements or changes.</li>
          <li>Promotional Messages: Occasionally sent to inform you of new features or services.</li>
        </ul>
      </>
    ),
  },
  {
    title: '7. Charges and Fees ',
    text: (
      <p>
        EntranceIQ does not charge users for email or SMS communications; however, standard message and data rates may apply as stipulated
        by your mobile carrier. By using this service, you agree that EntranceIQ is not responsible for any charges related to your use of
        these services.
      </p>
    ),
  },
  {
    title: '8. Termination and Suspension of Services ',
    text: (
      <>
        <p>
          EntranceIQ reserves the right to suspend or terminate your subscription to email or SMS communications without prior notice if:
        </p>
        <ul>
          <li>You violate these terms and conditions.</li>
          <li>There are technical issues or other unforeseen circumstances that require such action.</li>
          <li>You opt-out of receiving further communications.</li>
        </ul>
      </>
    ),
  },
  {
    title: '9. Disclaimer of Warranties',
    text: (
      <p>
        All communications are provided on an 'as-is' and 'as-available' basis. EntranceIQ makes no warranties, express or implied,
        regarding the accuracy, reliability, or completeness of any communications.
      </p>
    ),
  },
  {
    title: '10. Limitation of Liability ',
    text: (
      <p>
        EntranceIQ is not liable for any damages arising from the use of, or inability to use, our communication services. This includes,
        but is not limited to, technical failures, delays, or errors in message delivery.
      </p>
    ),
  },
  {
    title: '11. Indemnification ',
    text: (
      <p>
        You agree to indemnify and hold EntranceIQ harmless from any claims, liabilities, damages, and expenses (including legal fees)
        arising out of your use of the communication services or any violation of these terms.
      </p>
    ),
  },
  {
    title: '12. Governing Law ',
    text: (
      <p>
        These Terms and Conditions are governed by the laws of the state in which EntranceIQ is incorporated, without regard to its conflict
        of laws principles. Any disputes will be resolved in the jurisdiction of the state or federal courts located therein.
      </p>
    ),
  },
  {
    title: '13. Contact Information ',
    text: (
      <>
        <p>
          These Terms and Conditions are governed by the laws of the state in which EntranceIQ is incorporated, without regard to its
          conflict of laws principles. Any disputes will be resolved in the jurisdiction of the state or federal courts located therein.{' '}
        </p>
        <ul>
          <li>Email: support@entranceiq.net</li>
          <li>Phone: 561-503-4500</li>
        </ul>
      </>
    ),
  },
];
