import React from 'react';
import { Button, Modal } from 'antd';
import { INotification } from 'common/services/notification-service/notification.service.types';
import DateTimeView from 'features/eiq-connect/components/date-time-view/date-time-view';

type NotificationDetailsModalProps = {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
  notification: INotification;
};

export const NotificationDetailsModal = ({ isOpened, setIsOpened, notification }: NotificationDetailsModalProps) => {
  return (
    <Modal title={notification.subject} open={isOpened} onOk={() => setIsOpened(false)} onCancel={() => setIsOpened(false)} footer={false}>
      <div>
        <DateTimeView dateTime={notification.createdAt} />
      </div>
      <br />
      <div dangerouslySetInnerHTML={{ __html: notification.message }} />
      <br />
      <div>
        <Button type="primary" onClick={() => setIsOpened(false)} block>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default NotificationDetailsModal;
