import { Checkbox } from 'antd';
import './checkbox-group.scss';
import { CheckboxGroupProps } from './checkbox-group.types';

export const CheckboxGroup = ({ options, label, allText = 'All', value, onChange, disabled }: CheckboxGroupProps) => {
  const onCheckAllChange = (e: any) => {
    if (onChange) {
      onChange(e.target.checked ? options.map((opt: any) => opt.value) : []);
    }
  };

  return (
    <div className="checkbox-all-group">
      {label && <span className="checkbox-all-group__label">{label}</span>}
      <Checkbox disabled={disabled} onChange={onCheckAllChange} checked={value?.length === options.length}>
        {allText}
      </Checkbox>
      <Checkbox.Group disabled={disabled} options={options} value={value} onChange={onChange} />
    </div>
  );
};
