import { TextWithTitle } from 'common/components/text/text-with-title';
import { termsAndConditionsData } from './terms-and-conditions.const';
import BottomHeader from 'features/eiq-connect/components/bottom-header/bottom-header';
import TopHeader from 'features/eiq-connect/components/top-header/top-header';

export const TermsAndConditions = () => {
  return (
    <div className="eiq-terms-page">
      <div className="eiq-header">
        <TopHeader />
        <BottomHeader title="Terms" />
      </div>
      <div className="eiq-content">
        <h1>Terms and Conditions for Email and SMS Communications </h1>
        {termsAndConditionsData.map((item, index) => (
          <TextWithTitle key={index} title={item.title} text={item.text} />
        ))}
      </div>
    </div>
  );
};
