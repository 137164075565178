import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface ModalContextType {
  setIsOpened: (isOpend: boolean) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
  checkModalCondition: () => Promise<boolean>;
  modalComponent: React.ComponentType<{ isOpened: boolean; setIsOpened: (isOpened: boolean) => void }>;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ checkModalCondition, modalComponent, children }) => {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    const checkModal = async () => {
      const shouldShowModal = await checkModalCondition();
      if (shouldShowModal) {
        setIsOpened(true);
      }
    };

    checkModal();
  }, [checkModalCondition]);

  const value: ModalContextType = { setIsOpened: setIsOpened };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {React.createElement(modalComponent, { isOpened, setIsOpened })}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`useModalContext must be used within a ModalProvider`);
  }
  return context;
};
