import { GuestValidity } from 'common/enums/guest-validity.enum';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { VehicleStatusEnum } from 'common/enums/vehicle-status.enum';

export const TEST_CONSTANT = 'constant';
export const DATE_FORMAT = 'DD MMM YYYY';
export const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024;
export const ownershipTypeStatusColorMap: Record<ResidentTypesEnum, string> = {
  [ResidentTypesEnum.Owner]: '#C1FFEA',
  [ResidentTypesEnum.Renter]: '#FFB68C',
  [ResidentTypesEnum.Manager]: '#FFD889',
  [ResidentTypesEnum.Occupant]: '#EECAFF',
  [ResidentTypesEnum.Spouse]: '#C1FFEA',
  [ResidentTypesEnum.Roommate]: '#C1FFEA',
  [ResidentTypesEnum.Partner]: '#C1FFEA',
  [ResidentTypesEnum.SignificantOther]: '#C1FFEA',
  [ResidentTypesEnum.AdultChild]: '#C1FFEA',
  [ResidentTypesEnum.UnderAgeChild]: '#C1FFEA',
  [ResidentTypesEnum.NonResident]: '#EECAFF',
  [ResidentTypesEnum.Employee]: '#EECAFF',
  [ResidentTypesEnum.ClubMember]: '#EECAFF',
  [ResidentTypesEnum.Developer]: '#EECAFF',
};
export const ownershipTypeStatusMap: Record<ResidentTypesEnum, string> = {
  [ResidentTypesEnum.Owner]: 'Owner',
  [ResidentTypesEnum.Renter]: 'Renter',
  [ResidentTypesEnum.Manager]: 'Manager',
  [ResidentTypesEnum.Occupant]: 'Occupant',
  [ResidentTypesEnum.Spouse]: 'Spouse',
  [ResidentTypesEnum.Roommate]: 'Roommate',
  [ResidentTypesEnum.Partner]: 'Partner',
  [ResidentTypesEnum.SignificantOther]: 'Significant Other',
  [ResidentTypesEnum.AdultChild]: 'Adult Child',
  [ResidentTypesEnum.UnderAgeChild]: 'Under Age Child',
  [ResidentTypesEnum.NonResident]: 'Non-resident',
  [ResidentTypesEnum.Employee]: 'Employee',
  [ResidentTypesEnum.ClubMember]: 'Club Member',
  [ResidentTypesEnum.Developer]: 'Developer',
};

export const vehicleStatusColorMap: Record<VehicleStatusEnum, string> = {
  [VehicleStatusEnum.Undefined]: '#DCDCDC',
  [VehicleStatusEnum.Approved]: '#C1FFEA',
  [VehicleStatusEnum.Pending]: '#DBDBDB',
};

export const vehicleStatusMap: Record<VehicleStatusEnum, string> = {
  [VehicleStatusEnum.Undefined]: 'Undefined',
  [VehicleStatusEnum.Approved]: 'Approved',
  [VehicleStatusEnum.Pending]: 'Pending approval',
};

export const validityMap: Record<GuestValidity, string> = {
  [GuestValidity.Undefined]: 'Undefined',
  [GuestValidity.OneTime]: 'One time',
  [GuestValidity.Permanent]: 'Permanent',
  [GuestValidity.Today]: 'Today',
  [GuestValidity.Tomorrow]: 'Tomorrow',
  [GuestValidity.DateRange]: 'Date range',
};

export const phoneMask = '999-999-9999';

export enum TrafficLogVisitorType {
  Undefined = 0,
  Resident = 1,
  Guest = 2,
  Family = 3,
  Service = 4,
  UrgentService = 12,
  Delivery = 5,
  RideShare = 6,
  Employee = 14,
  // Party = 7,
  UnlistedGuest = 8,
  UnlistedService = 9,
  UnlistedDelivery = 10,
  UnlistedRideShare = 11,
  UnlistedUrgentService = 13,
  UnlistedEmployee = 15,
}
