import { makeAutoObservable, toJS } from 'mobx';
import {
  IGuestRead,
  INotificationChannel,
  IResidentDetails,
  MassNotificationSettings,
} from '../../../common/services/residents-service/residents.service.types';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { IWebGuestFilter } from '../pages/guests/eiq-connect-filter/eiq-connect-filter.types';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { IGuestVoiceForm } from '../pages/guests/components/voice-form/voice-form.type';
import { IAddressOccupant, ISharedResidentAddressData } from 'common/services/guest-service/guest.service.types';
import { ICitationFilter } from '../pages/citations/citation-filter/citation-filter.types';
import { NotificationType } from 'common/enums/notification-type.enum';
import { INotificationFilter, INotificationPreviewData } from '../pages/notifications/notifications.types';

class EiqConnectStore {
  selectedAddressesId: number[] | string[] | null;
  selectedGuest: IGuestRead[] | null;
  residentDetails: IResidentDetails | null;
  addressOptions?: DefaultOptionType[];
  guestFilters?: IWebGuestFilter | null;
  guestVoiceFrom?: IGuestVoiceForm | null;
  addressOccupants: IAddressOccupant[];
  citationFilter?: ICitationFilter | null;
  notificationFilter?: INotificationFilter | null;
  notificationPreviewData?: INotificationPreviewData | null;

  constructor() {
    makeAutoObservable(this);
    this.selectedAddressesId = null;
    this.residentDetails = null;
    this.selectedGuest = null;
    this.addressOccupants = [];
  }

  setGuestVoiceFrom(voiceFrom?: IGuestVoiceForm | null) {
    this.guestVoiceFrom = voiceFrom;
  }

  setSelectedAddressesId(selectedAddressId: number[] | string[] | null) {
    this.selectedAddressesId = selectedAddressId;

    console.log('selectedAddressId', selectedAddressId);
  }

  setResidentDetails(residentAddresses: IResidentDetails | null) {
    this.residentDetails = residentAddresses;

    this.addressOptions = residentAddresses?.addresses?.map((item, index) => ({
      title: formatAddressLabel(item.address),
      value: index,
    }));
  }

  setSelectedGuest(selectedGuest: IGuestRead[] | null) {
    this.selectedGuest = selectedGuest;
  }

  setGuestFilters(guestFilters: IWebGuestFilter | null) {
    this.guestFilters = guestFilters;
  }

  setCitationFilter(citationFilter: ICitationFilter | null) {
    this.citationFilter = citationFilter;
  }

  setNotificationFilter(filter: INotificationFilter | null) {
    this.notificationFilter = filter;
  }

  setNotificationPreviewData(data: INotificationPreviewData | null) {
    this.notificationPreviewData = data;
  }

  setOccupants(occupants: IAddressOccupant[]) {
    this.addressOccupants = occupants;
  }

  updateSharedResidentAddressId(residentId: number, residentAddressId: number, sharedResidentAddress: ISharedResidentAddressData) {
    const occupant = this.addressOccupants.find((i) => i.residentId === residentId);
    if (occupant) {
      const index = occupant.sharedResidentAddressData?.findIndex((i) => i.sharedResidentAddressId === residentAddressId);

      if (index !== -1) {
        const item = occupant.sharedResidentAddressData![index!];
        occupant.sharedResidentAddressData![index!] = {
          ...item,
          isGuestShared: sharedResidentAddress.isGuestShared ?? item.isGuestShared,
          isVehicleShared: sharedResidentAddress.isVehicleShared ?? item.isVehicleShared,
        };
      } else {
        occupant.sharedResidentAddressData!.push(sharedResidentAddress);
      }
    }
  }

  public get occupants() {
    return toJS(this.addressOccupants);
  }

  public get preferredNotificationType() {
    if (!this.residentDetails || !this.residentDetails.notificationChannels) {
      return NotificationType.Undefined;
    }
    const preferredChannel = this.residentDetails.notificationChannels.find(
      (channel: INotificationChannel) => channel.isPreferred === true,
    );

    return preferredChannel?.notificationType ?? NotificationType.Undefined;
  }

  public get massNotificationSettings() {
    let massNotificationSettings: MassNotificationSettings = {
      isMassEmailAllowed: null,
      isMassPushAllowed: null,
      isMassSMSAllowed: null,
    };

    if (this.residentDetails?.notificationChannels) {
      this.residentDetails.notificationChannels.forEach((channel: INotificationChannel) => {
        if (channel.allowsMassNotifications != null) {
          switch (channel.notificationType) {
            case NotificationType.Email:
              massNotificationSettings.isMassEmailAllowed = channel.allowsMassNotifications;
              break;
            case NotificationType.Push:
              massNotificationSettings.isMassPushAllowed = channel.allowsMassNotifications;
              break;
            case NotificationType.SMS:
              massNotificationSettings.isMassSMSAllowed = channel.allowsMassNotifications;
              break;
          }
        }
      });
    }

    return massNotificationSettings;
  }

  public get selectedAddresses() {
    return this.selectedAddressesId?.map((index: number | string) => {
      return this.residentDetails?.addresses[+index];
    });
  }
}

const eiqConnectStore = new EiqConnectStore();
export default eiqConnectStore;
